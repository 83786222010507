import type { Language } from '@square/ignition';
import { CodeExampleLanguage } from '@squareup/dex-types-oas';

const codeLanguageDisplayMap = new Map<CodeExampleLanguage, string>([
  ['curl', 'cURL'],
  ['java', 'Java'],
  ['csharp', 'C#'],
  ['php', 'PHP'],
  ['javascript', 'Node.js'],
  ['python', 'Python'],
  ['ruby', 'Ruby'],
  ['go', 'Go'],
]);

const langMap = new Map<CodeExampleLanguage, Language>([
  ['curl', 'CURL' as Language],
  ['ruby', 'RUBY' as Language],
  ['csharp', 'CSHARP' as Language],
  ['java', 'JAVA' as Language],
  ['javascript', 'JAVASCRIPT' as Language],
  ['python', 'PYTHON' as Language],
  ['php', 'PHP' as Language],
]);

function languageToIgnitionLanguage(
  lang: CodeExampleLanguage
): Language | undefined {
  return langMap.get(lang);
}

function golangClientSetup() {
  return `package example

import (
    client "github.com/square/square-go-sdk/client"
    option "github.com/square/square-go-sdk/option"
)

func do() () {
    client := client.NewClient(
        option.WithToken(
            "YOUR_ACCESS_TOKEN",
        ),
    )
}`;
}

export {
  languageToIgnitionLanguage,
  codeLanguageDisplayMap,
  golangClientSetup,
};
