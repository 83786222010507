import {
  OneTrustCategory,
  getCookie,
  setCookie,
} from '@square/onetrust-compliant-access';
import { setCodeLanguage } from '@squareup/dex-data-shared-main-data-slices';
import { useSharedDispatch } from '@squareup/dex-store-access-shared-main-dispatch';
import {
  CodeExampleLanguage,
  codeExampleLanguages,
} from '@squareup/dex-types-oas';
import { isServerContext } from '@squareup/dex-utils-environment';
import { useCallback, useEffect, useRef } from 'react';

import { useSelectCodeLanguage } from '../selectors';

const LANGUAGE_COOKIE_NAME = 'firestorm_code_language_preference';
const defaultLanguage = 'curl';

const isCodeExampleLanguage = (
  language?: string
): language is CodeExampleLanguage => {
  return codeExampleLanguages.includes(language as CodeExampleLanguage);
};

function getCodeLanguageStorageValue(): CodeExampleLanguage {
  if (isServerContext()) {
    return defaultLanguage;
  }

  const storedLanguage = getCookie(LANGUAGE_COOKIE_NAME) as string;
  return isCodeExampleLanguage(storedLanguage)
    ? storedLanguage
    : defaultLanguage;
}

/**
 * A helper hook that makes using the code language pieces as if it was the `useState` API
 * Essentially, it does a few things:
 * 1. Listens to changes in the shared redux tree to determine the users current code language
 * 2. Whenever the user sets a new language, it also updates the cookie along with the redux state
 * 3. On page load, it will update the code language to be that of the cookie, if it exists.
 * @returns A tuple of the current code language, and a setter to update the language.
 */
const useCodeLanguage = (): [
  CodeExampleLanguage,
  (lang: CodeExampleLanguage, withSimulatedLoading?: boolean) => void,
  boolean
] => {
  const codeLanguage = useSelectCodeLanguage();
  const dispatch = useSharedDispatch();

  const timeoutRef = useRef<number>();

  const setLang = useCallback(
    (lang: CodeExampleLanguage, withSimulatedLoading?: boolean) => {
      setCookie(LANGUAGE_COOKIE_NAME, lang, OneTrustCategory.FUNCTIONAL);

      if (withSimulatedLoading) {
        if (timeoutRef.current) {
          // Clear the previous timeout if it exists
          clearTimeout(timeoutRef.current);
        }
        dispatch(setCodeLanguage({ lang, isLoading: true }));

        timeoutRef.current = setTimeout(() => {
          dispatch(setCodeLanguage({ lang, isLoading: false }));
        }, 1000) as unknown as number;
      } else {
        dispatch(setCodeLanguage({ lang, isLoading: false }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const storedCookieLang = getCodeLanguageStorageValue();
    dispatch(setCodeLanguage({ lang: storedCookieLang, isLoading: false }));
    // Only run this once on page load to set the cookie
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [codeLanguage.lang, setLang, codeLanguage.isLoading];
};

export { useCodeLanguage };
